import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
	className?: string;
	color?: string;
	size?: number;
}

const TrashIcon = ({ className = '', color = '#1F1F1F', size = 24 }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				d="M8.5408 0.833425C8.44014 0.832136 8.34065 0.85518 8.25081 0.900597C8.16096 0.946014 8.08342 1.01246 8.02477 1.09428C7.96612 1.1761 7.9281 1.27088 7.91394 1.37055C7.89979 1.47023 7.90992 1.57184 7.94347 1.66676H6.0994C5.33106 1.66676 4.61849 2.04852 4.19266 2.68727L3.20633 4.16676H3.12414C3.04132 4.16559 2.95909 4.18089 2.88223 4.21177C2.80538 4.24266 2.73543 4.28851 2.67645 4.34666C2.61747 4.40481 2.57063 4.47411 2.53866 4.55052C2.5067 4.62693 2.49023 4.70893 2.49023 4.79176C2.49023 4.87459 2.5067 4.95659 2.53866 5.033C2.57063 5.10941 2.61747 5.17871 2.67645 5.23686C2.73543 5.29501 2.80538 5.34086 2.88223 5.37175C2.95909 5.40263 3.04132 5.41793 3.12414 5.41676H16.8741C16.957 5.41793 17.0392 5.40263 17.116 5.37175C17.1929 5.34086 17.2628 5.29501 17.3218 5.23686C17.3808 5.17871 17.4276 5.10941 17.4596 5.033C17.4916 4.95659 17.508 4.87459 17.508 4.79176C17.508 4.70893 17.4916 4.62693 17.4596 4.55052C17.4276 4.47411 17.3808 4.40481 17.3218 4.34666C17.2628 4.28851 17.1929 4.24266 17.116 4.21177C17.0392 4.18089 16.957 4.16559 16.8741 4.16676H16.7919L15.8056 2.68727C15.3798 2.04852 14.6668 1.66676 13.8989 1.66676H12.0548C12.0884 1.57184 12.0985 1.47023 12.0843 1.37055C12.0702 1.27088 12.0322 1.1761 11.9735 1.09428C11.9149 1.01246 11.8373 0.946014 11.7475 0.900597C11.6576 0.85518 11.5581 0.832136 11.4575 0.833425H8.5408ZM3.73774 6.66676L4.63455 15.0359C4.75955 16.2034 5.73903 17.0834 6.9132 17.0834H13.0851C14.2588 17.0834 15.2383 16.2034 15.3637 15.0359L16.2605 6.66676H3.73774Z"
				fill={color}
			/>
		</svg>
	);
};

export default TrashIcon;
