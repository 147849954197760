import React from 'react';

interface Props {
	className?: string;
	color?: string;
	size?: number;
}

const SearchIcon = ({ className = '', color = 'black', size = 24 }: Props) => {
	return (
		<svg
			className={className ?? ''}
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M20.9999 21.0004L16.6499 16.6504"
				stroke={color}
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default SearchIcon;
