// File for make the icon inport can be one line
export { default as BatarIcon } from './BatarIcon';
export { default as TilbodIcon } from './TilbodIcon';
export { default as KokurIcon } from './KokurIcon';
export { default as VefjurIcon } from './VefjurIcon';
export { default as DrykkirIcon } from './DrykkirIcon';
export { default as SnakkIcon } from './SnakkIcon';
export { default as SalotIcon } from './SalotIcon';
export { default as ProfileIcon } from './ProfileIcon';
export { default as CloseIcon } from './CloseIcon';
export { default as TrashIcon } from './TrashIcon';
export { default as CartIcon } from './CartIcon';
export { default as OrderIcon } from './OrderIcon';
export { default as SearchIcon } from './SearchIcon';
export { default as EditOrderIcon } from './EditOrderIcon';
