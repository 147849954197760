import React from 'react';

interface Props {
	className?: string;
	color?: string;
	size?: number;
}

const CartIcon = ({ className = '', color = '#FFFFFF', size = 24 }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				d="M1.33241 1.33307C1.24407 1.33183 1.15636 1.34815 1.07438 1.38109C0.992404 1.41403 0.91779 1.46294 0.854877 1.52497C0.791964 1.587 0.742006 1.66091 0.707908 1.74242C0.673809 1.82392 0.65625 1.91139 0.65625 1.99974C0.65625 2.08809 0.673809 2.17556 0.707908 2.25707C0.742006 2.33857 0.791964 2.41248 0.854877 2.47451C0.91779 2.53654 0.992404 2.58545 1.07438 2.61839C1.15636 2.65134 1.24407 2.66766 1.33241 2.66641H2.58502C2.73562 2.66641 2.86129 2.76072 2.90403 2.90599L5.74517 12.5648C5.99453 13.4127 6.77868 13.9997 7.66314 13.9997H14.9808C15.8798 13.9997 16.6735 13.3922 16.9105 12.5258L18.6423 6.17552C18.6693 6.0766 18.6732 5.97277 18.6535 5.87212C18.6339 5.77147 18.5913 5.6767 18.5291 5.5952C18.4668 5.51369 18.3866 5.44763 18.2947 5.40217C18.2028 5.35671 18.1016 5.33306 17.9991 5.33307H5.00819L4.18397 2.52969H4.18267C3.97474 1.82296 3.32108 1.33307 2.58502 1.33307H1.33241ZM7.99908 15.3331C7.64546 15.3331 7.30632 15.4736 7.05627 15.7236C6.80622 15.9736 6.66574 16.3128 6.66574 16.6664C6.66574 17.02 6.80622 17.3592 7.05627 17.6092C7.30632 17.8593 7.64546 17.9997 7.99908 17.9997C8.3527 17.9997 8.69184 17.8593 8.94189 17.6092C9.19194 17.3592 9.33241 17.02 9.33241 16.6664C9.33241 16.3128 9.19194 15.9736 8.94189 15.7236C8.69184 15.4736 8.3527 15.3331 7.99908 15.3331ZM14.6657 15.3331C14.3121 15.3331 13.973 15.4736 13.7229 15.7236C13.4729 15.9736 13.3324 16.3128 13.3324 16.6664C13.3324 17.02 13.4729 17.3592 13.7229 17.6092C13.973 17.8593 14.3121 17.9997 14.6657 17.9997C15.0194 17.9997 15.3585 17.8593 15.6086 17.6092C15.8586 17.3592 15.9991 17.02 15.9991 16.6664C15.9991 16.3128 15.8586 15.9736 15.6086 15.7236C15.3585 15.4736 15.0194 15.3331 14.6657 15.3331Z"
				fill={color}
			/>
		</svg>
	);
};

export default CartIcon;
