import React from 'react';

interface Props {
	className?: string;
	color?: string;
	size?: number;
}

const CloseIcon = ({ className = '', color = '#414A53', size = 24 }: Props) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className ?? ''}
		>
			<path
				d="M18 6L6 18"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6 6L18 18"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CloseIcon;
